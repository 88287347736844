import { useEffect, useState } from 'react';

import { useNavigate, useParams } from "react-router-dom";
import { getFirestore, doc, getDoc, Timestamp, FirestoreDataConverter, DocumentData, QueryDocumentSnapshot, SnapshotOptions, setDoc, updateDoc } from "firebase/firestore";
import { useAuthState } from 'react-firebase-hooks/auth';

import auth from '../../services/auth';
import app from '../../services/firebase';

import { slk, SurveyModel } from "survey-core";

import 'survey-analytics/survey.analytics.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import "./admin.css";

import _ from 'lodash';
import { Survey } from 'survey-react-ui';
import moment from 'moment';

slk(
    "NDBhNThlYzYtN2EwMy00ZTgxLWIyNGQtOGFkZWJkM2NlNjI3OzE9MjAyNS0wNy0xOSwyPTIwMjUtMDctMTksND0yMDI1LTA3LTE5"
);

const EEventConverter: FirestoreDataConverter<ExpanseEvent> = {
    toFirestore(event: ExpanseEvent): DocumentData {
        return {
            ...event,
            questions: JSON.stringify(event.questions || {}),
            theme: JSON.stringify(event.theme || {}),
            preRegDate: event.preRegDate ? Timestamp.fromDate(moment(event.preRegDate).startOf('day').toDate()) : null,
            startDate: Timestamp.fromDate(moment(event.startDate).startOf('day').toDate()),
            endDate: Timestamp.fromDate(moment(event.endDate).endOf('day').toDate()),
            fordEventID: event.fordEventID || null,
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): ExpanseEvent {
        const data = snapshot.data(options);
        return {
            id: snapshot.id,
            name: data.name,
            _preEventID: data._preEventID,
            preRegDate: data.preRegDate?.toDate(),
            startDate: data.startDate.toDate(),
            endDate: data.endDate.toDate(),
            confirmationEmail: data.confirmationEmail,
            reminderEmail: data.reminderEmail,
            thankYouEmail: data.thankYouEmail,
            checkInDisplay: data.checkInDisplay,
            disabled: data.disabled,
            questions: JSON.parse(data.questions),
            theme: JSON.parse(data.theme),
            thanks: data.thanks,
            fordEventID: data.fordEventID || undefined,
        };
    },
};

function DashboardScreen() {
    const navigate = useNavigate();
    const params = useParams();

    const [user, userLoading, userError] = useAuthState(auth);
    const [thisEvent, setThisEvent] = useState<ExpanseEvent>();
    const [thisSurvey, setThisSurvey] = useState<SurveyModel>();

    const db = getFirestore(app);
    const eventID: string = params.eventID!;

    useEffect(() => {
        console.error(userError);
    }, [userError]);

    useEffect(() => {
        if (userLoading) return;

        if (!user) {
            navigate('./login');
        }

        if (eventID === 'new') {
            setThisEvent({
                id: 'new',
                name: 'New Event',
                questions: {},
                theme: {},
                startDate: moment().add(7, 'days').toDate(),
                endDate: moment().add(7, 'days').toDate(),
            });
        } else {
            // get the event
            const eventRef = doc(db, "events", eventID).withConverter(EEventConverter);

            getDoc(eventRef).then((event) => {
                const eventData = event.data();
                setThisEvent(eventData);
            });
        }
    }, [userLoading]);

    useEffect(() => {
        if (!thisEvent) {
            return;
        }

        const surveyData = {
            "title": thisEvent.id === 'new' ? 'New Event' : `Edit Event ${thisEvent.id}`,
            "finishButton": "Save",
            "completedHtml": "Event Saved",
            "navigateToUrlOnCondition": [
                {
                    "expression": "{editSurvey} = true",
                    "url": "/admin/event/{id}/survey"
                },
                {
                    "expression": "{editSurvey} != true",
                    "url": "/admin"
                }
            ],
            "showQuestionNumbers": "off",
            "clearInvisibleValues": false,
            "checkErrorsMode": "onValueChanged",
            "autoGrowComment": true,
            "completeText": "Save",
            "widthMode": "responsive",
            "pages": [
                {
                    "name": "eventPage",
                    "elements": [
                        {
                            "type": "text",
                            "name": "id",
                            "title": "Event ID",
                            "description": "The Event ID also serves as the URL (https://survey.expansemarketing.com/s/{id})",
                            "descriptionLocation": "underInput",
                            "isRequired": true,
                            "readOnly": thisEvent.id !== 'new'
                        },
                        {
                            "type": "text",
                            "name": "name",
                            "title": "Event Name",
                            "isRequired": true
                        },
                        {
                            "type": "text",
                            "inputType": "date",
                            "name": "startDate",
                            "title": "Event Start",
                            "isRequired": true
                        },
                        {
                            "type": "text",
                            "inputType": "date",
                            "name": "endDate",
                            "title": "Event End",
                            "isRequired": true,
                            "validators": [
                                {
                                    "type": "expression",
                                    "text": "Event End must not be before Event Start",
                                    "expression": "{startDate} <= {endDate}"
                                }
                            ]
                        },
                        {
                            "type": "text",
                            "inputType": "date",
                            "name": "preRegDate",
                            "title": "Begin Pre-registration on"
                        },
                        {
                            "type": "text",
                            "name": "fordEventID",
                            "title": "Ford Event ID",
                            "description": "Optional: The corresponding Ford event ID if this event is linked to a Ford event",
                            "descriptionLocation": "underInput",
                            "validators": [
                                {
                                    "type": "regex",
                                    "text": "Ford Event ID must be a number",
                                    "regex": "^[0-9]*$"
                                }
                            ]
                        },
                        {
                            "type": "boolean",
                            "name": "editSurvey",
                            "defaultValue": false,
                            "visible": false,
                        }
                    ]
                }
            ]
        }

        const survey = new SurveyModel(surveyData);

        survey.data = {
            ...thisEvent,
            startDate: moment(thisEvent.startDate).format("YYYY-MM-DD"),
            endDate: moment(thisEvent.endDate).format("YYYY-MM-DD"),
            preRegDate: thisEvent.preRegDate ? moment(thisEvent.preRegDate).format("YYYY-MM-DD") : undefined,
        };

        survey.addNavigationItem({
            id: "sv-save-and-edit-survey",
            // To set the button text, use the `title` property  if you don't use localization:
            title: "Save and Edit Survey",
            action: () => {
                survey.setValue("editSurvey", true);
                survey.completeLastPage();
            },
            css: "nav-button",
            innerCss: "sd-btn nav-input"
        });

        survey.onServerValidateQuestions.add((sender, { data, errors, complete }) => {
            if (thisEvent.id === 'new') {
                const eventRef = doc(db, "events", data.id);
                getDoc(eventRef).then((event) => {
                    if (event.exists()) {
                        errors["id"] = "Event ID \"{id}\" already exists";
                    }
                    complete();
                });
            } else {
                complete();
            }
        });

        survey.onComplete.add(function (sender, options) {
            const eventData = _.omit(sender.data, ["editSurvey", "questions", "theme"]);
            console.log("eventData", eventData);

            if (thisEvent.id !== 'new') {
                console.log("Updating Event...", thisEvent.id);
                options.showSaveInProgress("Updating Event...");
                const eventRef = doc(db, "events", thisEvent.id);
                eventData.startDate = Timestamp.fromDate(moment(eventData.startDate).startOf('day').toDate());
                eventData.endDate = Timestamp.fromDate(moment(eventData.endDate).endOf('day').toDate());
                if (eventData.preRegDate) {
                    eventData.preRegDate = Timestamp.fromDate(moment(eventData.preRegDate).startOf('day').toDate());
                }
                updateDoc(eventRef, eventData).then(() => {
                    options.showSaveSuccess("Event Updated");
                }).catch(err => {
                    options.showSaveError(err.message);
                });
            } else {
                console.log("Creating Event...", eventData.id);
                options.showSaveInProgress("Creating Event...");
                const eventRef = doc(db, "events", eventData.id);
                eventData.questions = "{}"; // default questions
                eventData.theme = "{}"; // default theme
                eventData.startDate = Timestamp.fromDate(moment(eventData.startDate).startOf('day').toDate());
                eventData.endDate = Timestamp.fromDate(moment(eventData.endDate).endOf('day').toDate());
                if (eventData.preRegDate) {
                    eventData.preRegDate = Timestamp.fromDate(moment(eventData.preRegDate).startOf('day').toDate());
                }
                setDoc(eventRef, eventData).then(() => {
                    options.showSaveSuccess("Event Created");
                }).catch(err => {
                    options.showSaveError(err.message);
                });
            }
        });
    
        setThisSurvey(survey);
    }, [thisEvent]);

    return (
        thisEvent && thisSurvey ? (
            <div>
                <Survey model={thisSurvey} />
            </div>
        ) : (
            <div>Loading...</div>
        )
    );
}

export default DashboardScreen;
