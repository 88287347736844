import { useEffect, useState } from "react";
import { Model, SurveyError } from "survey-core";
import { Survey } from "survey-react-ui";
import { FunctionFactory } from 'survey-core';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Loader } from "@progress/kendo-react-indicators";
import * as Sentry from "@sentry/react";
import showdown from 'showdown';
import { v4 as uuidv4 } from 'uuid';

import auth from '../services/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

// these are the custom surveyjs renderers
// while not referenced in the code they have to be imported to be registered
import { CheckboxVOIQuestion } from "../surveysjs_renderers/CheckboxVOI";
import { RadioGroupRowQuestion } from "../surveysjs_renderers/RadioButtonButton";
import { SurveyBookeoQuestion } from "../surveysjs_renderers/Bookeo";
import { EmailTextInput } from "../surveysjs_renderers/EmailTextInput";

import logo from '../assets/ford-signature.svg';
import { fordCSS } from "../themes/surveyJS/ford";
import "survey-core/defaultV2.min.css";
import "../themes/surveyJS/fds/custom-fds.css";

import "./Surveys.css";
import { prepareForSurvey, prepareSurveyOnQuestionAdded } from "../helpers/surveyTemplatesAll";
import FordFooter from "../components/FordFooter";

function validateEmail() {
    console.log('[validateEmail]', this.question.value);
    const email = this.question.value;

    this.question.setPropertyValue('didYouMean', "");

    if (!email) {
        this.returnResult();
        return;
    }

    fetch('https://us-central1-latitude-lead-system.cloudfunctions.net/validateEmail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
    }).then(response => {
        response.json().then((res) => {
            const { results } = res;

            let valid = true;
            console.log('this.survey', this.survey);
            console.log('this.question', this.question);
            console.log('validateEmail res', res);

            // bad emails are rejected
            if (results?.valid === false) {
                valid = false;
            }

            // disposable email services are rejected
            if (results?.is_disposable === true) {
                valid = false;
            }

            // reject delivery_confidence below 20
            if (results?.delivery_confidence < 20) {
                valid = false;
            }

            // typos are rejected with correction
            if (results.did_you_mean) {
                valid = true;
                this.question.setPropertyValue('didYouMean', results.did_you_mean);

                console.log('this.question after', this.question.didYouMean);
            }

            this.returnResult(valid);
        });
    }).catch(err => {
        Sentry.captureException(err);
        alert(err);
    });
}

FunctionFactory.Instance.register("validateEmail", validateEmail, true);

const extractUTM = () => {
    // Get the UTM parameters from the URL
    var search = window.location.search;
    var params = new URLSearchParams(search);

    const utm = {};

    // Loop through each UTM parameter
    for (var [key, value] of params.entries()) {
        // Check if the parameter is "utm_source", "utm_medium", "utm_campaign", "utm_term", or "utm_content"
        if (["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"].includes(key)) {
            utm[key] = value;
        }
    }

    return utm;
}

function SurveyComponent() {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [user] = useAuthState(auth);
    const [thisSurvey, setThisSurvey] = useState();
    const [thisError, setThisError] = useState();
    const [thisEvent, setThisEvent] = useState();

    const converter = new showdown.Converter({
        openLinksInNewWindow: true,
    });

    const handleSurveyStarting = (sender, options) => {
        console.log('handleSurveyStarting', options);
    }

    useEffect(() => {
        fetch('https://us-central1-latitude-lead-system.cloudfunctions.net/getSurvey', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ eventID: params.eventID, preSurveyID: location.state?.preSurveyID }),
        }).then(response => {
            response.json().then((res) => {
                if (res.success) {
                    setThisEvent(res.event);
                    const themeJSON = JSON.parse(res.event.theme);

                    document.body.style.backgroundColor = themeJSON.cssVariables['--sjs-editor-background'];

                    document.title = res.event.name;
                    // if the favicon is defined
                    if (themeJSON.favicon) {
                        var link = document.querySelector("link[rel~='icon']");
                        link.href = themeJSON.favicon;
                    }

                    if (res.event._preEventID && !location.state?.preSurveyID) {
                        // this is a post-event survey, sign people out
                        navigate('./out');
                    }

                    const surveyJSON = JSON.parse(res.event.questions);

                    if (res.event.disabled && !user) {
                        surveyJSON.showCompleteButton = false;
                        surveyJSON.pages = [
                            {
                                "elements": [
                                    {
                                        "type": "html",
                                        "html": `<h4>${res.event.disabled}</h4>`
                                    }
                                ]
                            }
                        ]
                    }

                    const survey = new Model(surveyJSON);
                    survey.applyTheme(themeJSON);
                    
                    if (res.event.fordEventID) {
                        survey.css = fordCSS;
                        survey.questionErrorLocation = "bottom";
                    }

                    prepareForSurvey(survey);

                    survey.onAfterRenderSurvey.add((sender) => {
                        sender.showCompleteButton = surveyJSON.showCompleteButton;
                        sender.showNavigationButtons = surveyJSON.showNavigationButtons;

                        sender.setValue('start_time', new Date());
                        sender.setValue('survey_date', new Date());
                        sender.setValue('event_id', params.eventID);
                        sender.setValue('app_version', 'surveyjs_1.0');
                        sender.setValue('abandoned', 0);
                        sender.setValue('_utm', extractUTM());
                        sender.setValue('_referrer', window.frames?.top?.document?.referrer);
                        sender.setValue('_language', window.navigator?.language);
                        sender.setValue('device_id', window.navigator?.userAgent);
                        sender.setValue('_screenWidth', window.screen?.width);
                        sender.setValue('_offset', (new Date()).getTimezoneOffset());
                        sender.setValue('_timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
                        console.log('defaults set', sender.getValue('survey_date'));
                    });

                    survey.onServerValidateQuestions.add((sender, options) => {
                        // loop & look for Bookeo
                        survey.getAllQuestions().forEach(question => {
                            console.log('question', question.name, question.getType());
                            if (question.getType() === "bookeo" && options.data[question.name]) {
                                const bookeoData = {
                                    bookeoKey: sender.getQuestionByName(question.name).getPropertyValue("bookeoKey"),
                                    customFieldId: sender.getQuestionByName(question.name).getPropertyValue("customFieldId"),
                                    seats: sender.getQuestionByName(question.name).getPropertyValue("seats"),
                                    productId: sender.getQuestionByName(question.name).getPropertyValue("productId"),
                                    previousHoldId: options.data[question.name].previousHoldId,
                                    eventId: options.data[question.name].eventId,
                                    firstName: options.data["first_name"],
                                    lastName: options.data["last_name"],
                                    emailAddress: options.data["email"],
                                    phone: options.data["phone"],
                                    type: "mobile",
                                    customData: JSON.stringify({}),
                                }

                                fetch('https://us-central1-latitude-lead-system.cloudfunctions.net/makeBookeoBooking', {
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json' },
                                    body: JSON.stringify(bookeoData),
                                }).then(response => {
                                    response.json().then((data) => {
                                        if (!response.ok) {
                                            options.errors[question.name] = "Booking Failed, please select a different time.";
                                            sender.getQuestionByName(question.name).setPropertyValue("productId", sender.getQuestionByName(question.name).getPropertyValue("productId"));
                                            console.error('Bookeo API Booking Error', data);
                                            options.data[question.name] = null;
                                            options.complete();
                                        }

                                        const val = options.data[question.name];

                                        val.bookingNumber = data.bookingNumber;
                                        options.data[question.name] = val;
                                    });
                                }).catch(err => {
                                    console.error(err);
                                    options.errors[question.name] = "Booking Failed, please select a different time.";
                                    sender.getQuestionByName(question.name).clearValue();
                                    sender.getQuestionByName(question.name).setPropertyValue("productId", sender.getQuestionByName(question.name).getPropertyValue("productId"));
                                    options.complete();
                                });
                            }
                        });

                        options.complete();
                    });

                    survey.onComplete.add(async (sender, options) => {
                        console.log('onComplete', options);
                        const originalMesage = sender.completedHtml;
                        console.log('originalMesage', originalMesage);
                        sender.completedHtml = "<h3>Saving...</h3>";
                        options.showDataSaving('Saving...');

                        let surveyData = sender.data;

                        // set some default hidden properties
                        surveyData['_preSurveyID'] = location.state?.preSurveyID ? location.state.preSurveyID : null;
                        surveyData['_checkedIn'] = null;
                        surveyData['_checkedOut'] = null;
                        surveyData['_claimed'] = null;
                        surveyData['_used'] = null;
                        surveyData['_email'] = null;
                        surveyData['_sms'] = null;
                        surveyData['_exported'] = null;
                        surveyData['end_time'] = new Date();
                        surveyData['device_survey_guid'] = uuidv4(); // Use same GUID for both APIs

                        try {
                            // Save to Firestore
                            const firestoreRes = await fetch('https://us-central1-latitude-lead-system.cloudfunctions.net/saveSurvey', {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    eventID: params.eventID,
                                    survey: surveyData,
                                }),
                            });

                            if (!firestoreRes.ok) {
                                throw new Error(firestoreRes.statusText);
                            }

                            const firestoreData = await firestoreRes.json();
                            console.log('saved to firestore', firestoreData);

                            // If this is a Ford event, also save to Ford API
                            if (res.event.fordEventID) {
                                console.log('[Ford Event] Starting Ford API submission process');
                                console.log('[Ford Event] Event ID:', res.event.fordEventID);
                                
                                // Map survey questions using _ffs property
                                const ffsData = {};
                                console.log('[Ford Event] Initial survey data:', surveyData);
                                
                                survey.getAllQuestions().forEach(question => {
                                    const ffsKey = question.getPropertyValue('_ffs');
                                    console.log(`[Ford Event] Processing question: ${question.name}, FFS Key: ${ffsKey}, Value:`, question.value);
                                    
                                    if (ffsKey) {
                                        if (ffsKey === 'address_group') {
                                            // Handle address_group composite
                                            const addressData = question.value || {};
                                            console.log('[Ford Event] Processing address group:', addressData);
                                            ffsData['address1'] = addressData.address1 || null;
                                            ffsData['address2'] = addressData.address2 || null;
                                            ffsData['city'] = addressData.city || null;
                                            ffsData['state'] = addressData.state || null;
                                            ffsData['zip_code'] = addressData.zip_code || null;
                                            ffsData['country'] = addressData.country || null;
                                        } else if (ffsKey === 'voi') {
                                            // VOI is handled separately
                                            ffsData[ffsKey] = question.value;
                                            console.log('[Ford Event] Processing VOI:', ffsData[ffsKey]);
                                        } else {
                                            ffsData[ffsKey] = typeof question.value !== 'undefined' ? question.value : null;
                                            console.log(`[Ford Event] Setting ffsData[${ffsKey}]:`, ffsData[ffsKey]);
                                        }
                                    }
                                    // Also store in regular surveyData
                                    surveyData[question.valueName || question.name] = (typeof question.value === 'undefined' || question.value === null) ? null : question.value;
                                });

                                console.log('[Ford Event] Final ffsData:', ffsData);

                                // Build custom_data only with existing values
                                const customData = {};
                                ['overall_opinion', 'current_owner', 'brand_for_me', 'minorName1', 'minorName2', 'minorName3'].forEach(key => {
                                    console.log(`[Ford Event] Processing custom data key: ${key}, Value:`, surveyData[key]);
                                    if (surveyData[key] !== undefined && surveyData[key] !== null) {
                                        customData[key] = surveyData[key];
                                    }
                                });
                                surveyData.custom_data = Object.keys(customData).length > 0 ? JSON.stringify(customData) : null;
                                console.log('[Ford Event] Final custom_data:', surveyData.custom_data);

                                const fordPayload = {
                                    surveyCollection: [{
                                        event_id: res.event.fordEventID,
                                        device_survey_guid: surveyData.device_survey_guid,
                                        device_id: window.navigator.userAgent,
                                        start_time: surveyData.start_time,
                                        end_time: surveyData.end_time,
                                        survey_date: surveyData.survey_date,
                                        app_version: 'expanse_1.0',
                                        abandoned: 0,
                                        accepts_sms: 0,
                                        address2: '',
                                        survey_type: null,
                                        pre_drive_survey_guid: null,
                                        followup_survey_opt_in: null,
                                        vehicle_driven_most_make_id: null,
                                        vehicle_driven_most_model_id: null,
                                        vehicle_driven_most_year: null,
                                        how_likely_recommend_post: null,
                                        how_likely_acquire: null,
                                        how_likely_purchasing: null,
                                        how_likely_recommend: null,
                                        gender: null,
                                        age_bracket: null,
                                        impression_pre: null,
                                        impression_ev: null,
                                        impact_overall_opinion: null,
                                        birth_year: null,
                                        can_trust: null,
                                        optins: null,
                                        signature: null,
                                        minor_signature: null,
                                        ...ffsData,
                                        custom_data: surveyData.custom_data,
                                    }]
                                };
                                
                                console.log('[Ford Event] Final payload being sent to Ford API:', fordPayload);

                                // Save to Ford API
                                const fordRes = await fetch('https://pfg.latitudewebservices.com/microsite/v1/survey/upload/v9', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': '989ae554-08ca-4142-862c-0058407d2769',
                                    },
                                    body: JSON.stringify(fordPayload),
                                });

                                if (!fordRes.ok) {
                                    throw new Error(fordRes.statusText);
                                }

                                const fordData = await fordRes.json();
                                console.log('saved to ford api', fordData);

                                // If there are vehicles of interest, save those too
                                if (ffsData.voi && ffsData.voi.length) {
                                    const voiBody = ffsData.voi.map(vehicle_id => ({
                                        vehicle_id,
                                        device_survey_guid: surveyData.device_survey_guid,
                                        survey_vehicle_guid: uuidv4(),
                                    }));

                                    const voiRes = await fetch('https://pfg.latitudewebservices.com/microsite/v1/survey/insert/vehicles', {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': '989ae554-08ca-4142-862c-0058407d2769',
                                        },
                                        body: JSON.stringify(voiBody),
                                    });

                                    if (!voiRes.ok) {
                                        throw new Error(voiRes.statusText);
                                    }

                                    console.log('saved voi');
                                }
                            }

                            options.showDataSavingSuccess();
                            sender.completedHtml = originalMesage;
                            if (location.state?.preSurveyID) {
                                setTimeout(() => {
                                    navigate('./out');
                                }, 3000);
                            }
                        } catch (err) {
                            console.error('error', err);
                            Sentry.captureException(err);
                            options.showDataSavingError();
                        }
                    });

                    prepareSurveyOnQuestionAdded(null, { survey });

                    setThisSurvey(survey);
                } else {
                    setThisError(res);
                }
            });
        }).catch(err => {
            Sentry.captureException(err);
            alert(err);
        });
    }, [user]);

    return (
        thisSurvey ?
            <div className="gdux-ford">
                {
                    thisEvent.fordEventID && (
                        <div className="fds-logo-only-header">
                            <img src={logo} alt="Ford" className="fds-header-logo" />
                        </div>
                    )
                }
                <div id="fd-nxt">
                    <Survey model={thisSurvey} />
                </div>
                {
                    thisEvent.fordEventID && (
                        <FordFooter />
                    )
                }
            </div>
            :
            thisError ? <h2>{thisError.message}</h2> : <Loader type="converging-spinner" size="large" />
    )
}

export default SurveyComponent;